
import { Component, Vue } from "vue-property-decorator";
import HousfyPage from "@/components/protected/HousfyPage.vue";
import MoreInformationFlapForm from "@/components/forms/MoreInformationFlapForm.vue";
import ToolsList from "@/components/ToolsList.vue";
import OwnerPropertiesShimmer from "@/components/OwnerPropertiesShimmer.vue";
import {
  HousfyBannerWithCheckList,
  HousfyAlertMessage,
  HousfyPropertyCard,
} from "housfy-ui-lib";
import api from "@/api";
import ErrorService from "@/services/ErrorService";
import { IHousfyError } from "@/models/HousfyError";
import { IBackOptions } from "@/models/BackOptions";
import { TranslateResult } from "vue-i18n";
import { LeadOrigin } from "@/constants/OriginIds";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import { resolveRoutePath } from "@/router";
import { HousfyButton } from "housfy-ui-lib";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { TabbarIds } from "@/enums/HousfyPage";
import OwnerLeads from "@/components/OwnerLeads.vue";
import { IOwnerLeads } from "@/models/OwnerLeads";

@Component({
  name: "Owner",
  components: {
    HousfyPage,
    HousfyBannerWithCheckList,
    HousfyAlertMessage,
    HousfyPropertyCard,
    OwnerPropertiesShimmer,
    MoreInformationFlapForm,
    ToolsList,
    OwnerLeads,
    HousfyButton,
    HousfySvg,
  },
})
export default class Owner extends Vue {
  ownerLeads: IOwnerLeads = { items: [] };
  loading = false;
  formFlap = false;
  formId = "0";
  successAlert = false;
  originId = LeadOrigin.OWNER;
  activeTabId = TabbarIds.ACTIVITY;

  get pageSubtitle(): TranslateResult {
    const leadsCount = this.ownerLeads.items.length;
    if (!leadsCount) {
      return this.$t("common.youHaveNoActiveRentals");
    }
    return this.$t("common.youHaveAddedXProperties", {
      ownerLeadsCount: leadsCount,
      properties: this.$tc("common.properties", leadsCount),
    });
  }
  get backOptions(): IBackOptions {
    return {
      enabled: true,
      text: this.$t("common.back"),
      minimal: this.$mqm,
      preventDefault: false,
      fallbackHref: resolveRoutePath(MYHExternalRoutes.myhHome()),
    };
  }
  get bannerList(): TranslateResult[] {
    return [
      this.$t("common.secureCollectionEachMonth"),
      this.$t("common.tenantAndSolvencyAnalysis"),
      this.$t("common.incidentManagement"),
    ];
  }
  get alertMessage(): TranslateResult {
    const thanksText =
      "<strong>" + this.$t("common.thankYouForContactingUs") + "</strong> ";
    const callText = this.$t("common.weWillCallYouBack");
    const desktopMessage = thanksText + "<br/>" + callText;
    const mobileMessage = thanksText + callText;

    return this.$mqm ? mobileMessage : desktopMessage;
  }
  async mounted() {
    try {
      this.loading = true;
      this.ownerLeads = await api.owner().retrieveOwnerLeads();
    } catch (error) {
      ErrorService.handleGenericError(error as IHousfyError);
    } finally {
      this.loading = false;
    }
  }
  openForm(formId: string) {
    this.formFlap = true;
    this.formId = formId;
  }
  handleFormSuccess() {
    this.formFlap = false;
    this.successAlert = true;
  }
}
